@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import "~@fontsource */
/* @import "~@fontsource/classicourw"; */

@import url("https://use.typekit.net/hao3qft.css");

body {
  font-size: 17px;
  @apply text-base leading-8;
  font-family: "classicourw", "Genseki", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



@layer utilities {
  @variants responsive {
.h1-lg{
  @apply text-6xl tracking-wider ;
  font-family: "linotype-sabon", "Genwan";
}

.h2-lg{
  @apply text-4xl ;
  font-family: "linotype-sabon", "Genwan";
}

.h3-lg{
  @apply text-2xl tracking-wide  leading-7;
  font-family: "linotype-sabon", "Genwan";
}

.captions-lg{
  @apply tracking-wide font-normal;
  font-size: 0.9rem;
  font-family: "classicourw", "Genseki";
}

.body-lg{
  @apply text-base leading-8;
  font-size: 1.2rem;
  font-family: "classicourw", "Genseki";

}

.body2-lg{
  @apply font-normal text-base leading-8;
  font-size: 1rem;
  font-family: "classicourw", "Genseki";
}


.card-lg{
  @apply  leading-7;
  font-size: 1rem;
  font-family: "linotype-sabon", "Genwan";
}


.h1{
  @apply text-4xl tracking-wider ;
  font-family: "linotype-sabon", "Genwan";
}

.h2{
  @apply text-2xl ;
  font-family: "linotype-sabon", "Genwan";
}

.h3{
  @apply text-xl tracking-wide  leading-5;
  font-family: "linotype-sabon", "Genwan";
}

.captions{
  @apply tracking-wide font-normal leading-5;
  font-size: 0.9rem;
  font-family: "classicourw", "Genseki";
}


.body{
  @apply leading-7;
  font-family: "classicourw", "Genseki";

}

.body2{
  @apply font-normal text-base leading-8;
  font-size: 0.8rem;
  font-family: "classicourw", "Genseki";
}

.card{
  @apply  leading-7;
  font-size: 0.9rem;
  font-family: "linotype-sabon", "Genwan";
}

.vertical{
  writing-mode: vertical-rl;
}

.horizontal{
  writing-mode: horizontal-tb;
}

.w-fit-content{
  width:fit-content;
}

.h-fit-content{
  height:fit-content;
}

.darken-layer{
  background-image: linear-gradient(180deg, #DDE6ED 0%, #66838B 100%);
  mix-blend-mode: multiply;
  opacity: 0.7;
}

.max-w-33{
  max-width: calc(33.3% - 1px);
}

.max-w-49{
  max-width: calc(50% - 1px);
}

.divider-x{
  border-right:none;
  border-top: 1.2px solid;
  height:1.5px;
  flex-grow: 1;
  width:100%;
}

.divider-y{
  display:flex;
  border-top:none;
  border-right: 1.2px solid;
  width:1.5px;
}
.w-nav-lg{
   width:calc(100vw + 16rem);
}
}
}

.action-btn{
  border: 1px solid #A4A4A4;
  width:fit-content;
  height:fit-content;
  box-sizing: border-box;
  box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.25);
  border-radius: 1000px;
  animation: pulse 3s infinite;
}

.NavLinkDark:hover{
  color:#83C4D2;
  transform: scale(1.05);
  transition-duration: 0.2s;
}
.NavLink:hover{
  color:#D8E5E3;
  transform: scale(1.05);
  transition-duration: 0.2s;
}
.action-btn:hover{
 animation-play-state: paused;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}
.link-btn{
  background: #384A2C;
  border: 1px solid #A7C9AF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  width:100%;
  text-align: center;
  padding: 0.5rem 1rem;
  height:fit-content;
  box-sizing: border-box;
  transition-duration: 0.2s;
}

.link-btn:hover{
  background: #A7C9AF;
  border: 1px solid #C4C4C4;
  box-shadow: none;
  transform: scale(1.05);

}

.map-btn{
  background: #A7C9AF;
  border: 1px solid #A4A4A4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  width:100%;
  text-align: center;
  padding: 0.5rem 0;
  height:fit-content;
  box-sizing: border-box;
}

.glass{
  /* From https://css.glass */
background: rgba(255,255,255, 0.4);
box-shadow: 0 2px 2px rgba(155, 155, 155, 0.1);
backdrop-filter: blur(3px);
-webkit-backdrop-filter: blur(3px);
}


.glassDark{
  /* From https://css.glass */
background:  rgba(62, 79, 84, 0.4);
box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
backdrop-filter: blur(3px);
-webkit-backdrop-filter: blur(3px);
}

.border-landing-light{
  @apply divider-x sm:divider-y sm:h-64;
  border-color:#C9AD83;
}

.border-rooms-carousel{
  @apply divider-x lg:divider-y lg:h-64;
  border-color:#C9AD83;
  margin:auto;

}

.border-rooms-warm{
  @apply divider-x lg:divider-y lg:h-64;
  border-color:#C9AD83;
  margin:auto;

}

.border-rooms-cool{
  @apply divider-x lg:divider-y lg:h-64;
  border-color:#A7C9AF;
  margin:auto;
}

.border-destinations-cool{
  @apply divider-x;
  border-color:#A7C9AF;
  margin:auto;
}

.border-destinations-warm{
  @apply divider-x;
  border-color:#C9AD83;
  margin:auto;
}

.border-dark{
  @apply divider-x sm:divider-y;
  border-color:#3E4F54;
}

.border-accent-green{
  @apply divider-x sm:divider-y;
  border-color:#A7C9AF;
}

.border-warm-lightest{
  @apply divider-x sm:divider-y;
  border-color:#F1D7B6;
}

.saturate-70{
  filter: saturate(0.7);
}

.grid-link > div {
  cursor: pointer;
}

.genwan{
  font-family: "linotype-sabon", "Genwan";
}


.genseki{
  font-family: "Genseki";
}

[lang="en"] * {
  letter-spacing: 0px !important;
  line-height:1.5 !important;
}

[lang="en"] .leading-2 {
  letter-spacing: 0px !important;
  line-height:1.2 !important;
}


[lang="en"] h1 {
  letter-spacing: -0.002em !important;
  line-height:0.9 !important;
}

[lang="en"] h2, [lang="en"] h3 {
  letter-spacing: -0.002em !important;
  line-height:1.1 !important;
}

[lang="en"] .h3.opacity-50 {
  font-style:italic;
  opacity:0.7;
}

@media screen and (max-width:640px){
  [lang="en"] .h2 {
    @apply text-3xl ;
  }
}

@media screen and (max-width:640px){
  [lang="en"] .h1 {
    @apply text-6xl ;
  }
}
 
[lang="en"] .link-btn, [lang="en"] .map-btn {
  font-size: 1.4rem;
  font-weight:500;
  font-family: 'classicourw', sans-serif;
  padding-top: 0.75rem

}
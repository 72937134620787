//Genseki
@font-face {
    font-family: "Genseki";
    src: local('Genseki'),local('GenSeki'),local('GenSekiGothic'),local('GenSekiGothicTW'),local('Genseki-Gothic'),url("./fonts/Genseki/GenSekiGothicTW-R-01.woff2") format('woff2'),
        url("./fonts/Genseki/GenSekiGothicTW-R-01.woff") format('woff');
    font-weight: 400;
    font-display: swap;
}

//Genwan

@font-face {
    font-family: "Genwan";
    src: local('Genwan'),local('GenWanMin'),local('GenWanMinTW'),url("./fonts/Genwan/GenWanMinTW-R-01.woff2") format('woff2'),
    url("./fonts/Genwan/GenWanMinTW-R-01.woff") format('woff');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Genwan";
    src: local('Genwan'),local('GenWanMin'),local('GenWanMinTW'),url("./fonts/Genwan/GenWanMinTW-M-01.woff2") format('woff2'),
    url("./fonts/Genwan/GenWanMinTW-M-01.woff") format('woff');
    font-weight: 500;
    font-display: swap;
}
